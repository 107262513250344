/* Login.css */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f7fa;
  font-family: 'Arial', sans-serif;
}

.login-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.login-container form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
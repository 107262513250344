/* Container for the loading screen */
.loading-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

/* Background video */
.loading-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Content overlay */
.content-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 1;
}

/* FlyManta logo */
.flymanta-logo {
  width: 150px;
  margin-bottom: 30px;
}

/* Loading bar container */
.loading-bar {
  width: 80%;
  max-width: 600px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

/* Inner loading bar */
.loading-bar-inner {
  height: 100%;
  background-color: #00aaff; /* Color similar to FlyManta */
  width: 0%;
  transition: width 1s ease;
}

/* Loading text */
.loading-text {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 15px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

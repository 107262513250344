/* questionnaire.css */

body {
    background-color: #f0f4f8;
    font-family: 'Poppins', sans-serif;
  }
  
  .questionnaire-container {
    max-width: 700px;
    margin: 50px auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .questionnaire-container h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  .questionnaire-description {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .questionnaire-buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .questionnaire-buttons button {
    background-color: #ff8c42;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .questionnaire-buttons button:hover {
    background-color: #ff6c00;
  }
  
.input-field {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .input-field label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .input-field input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .input-field input:focus {
    border-color: #007bff;
    outline: none;
  }

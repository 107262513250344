.leaflet-routing-container {
    display: none;
  }
  
  .leaflet-div-icon {
    background: none;
    border: none;
  }
  
  .leaflet-popup-content-wrapper {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .leaflet-popup-content strong {
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
  }
  
/* SignUp.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f7fa;
    font-family: 'Arial', sans-serif;
  }
  
  .signup-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .signup-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .signup-container p {
    color: red;
    margin-top: 10px;
  }
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .success {
    color: green;
    margin-top: 10px;
  }
  
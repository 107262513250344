/* src/UnderConstruction.css */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


.uc-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.uc-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* S'assure que la vidéo est en arrière-plan */
}

.uc-content {
    position: relative;
    z-index: 1; /* Contenu au-dessus de la vidéo */
    text-align: center;
    color: white;
}

.uc-logo {
    max-width: 250px;
    margin-bottom: 30px;
    animation: uc-float 6s ease-in-out infinite;
}

@keyframes uc-float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
}

.uc-h1 {
    font-size: 4rem;
    margin: 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    font-family: 'Pacifico', cursive;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.uc-p {
    font-size: 1.5rem;
    margin: 15px 0;
    position: absolute;
    top: 150px; /* Ajuster selon la hauteur du titre */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.uc-highlight {
    color: #f39c12;
    font-weight: bold;
}

.uc-footer {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
}

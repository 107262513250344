/* src/components/common/Button.css */

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;  /* Flexbox for centering content */
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 130px; /* Set a minimum width for consistency */
  height: 40px; /* Fixed height for consistent appearance */
  overflow: hidden; /* Ensure that content does not overflow */
}

.btn:hover {
  background-color: #0056b3;
}

.btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  display: inline-block; /* Display inline-block to align within flexbox */
  vertical-align: middle; /* Vertical align to keep it centered */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

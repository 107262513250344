/* Couleur de fond de la page */
body {
  background-color: #b9b6b4;
}

/* Style pour le bandeau */
.banner {
  position: relative;
  width: 100%;
  height: 400px; /* Hauteur du bandeau */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

/* Vidéo en fond */
.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(70%); /* Assombrit la vidéo pour que le texte soit lisible */
}

/* Contenu dans le bandeau */
.banner-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
}

/* Titre et description à gauche */
.banner-text {
  max-width: 50%;
}

.banner-text h1 {
  font-size: 3rem;
  margin: 0;
}

.banner-text p {
  font-size: 1.5rem;
  margin-top: 10px;
}

/* Informations à droite */
.banner-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.info-pill {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2); /* Pastille semi-transparente */
  padding: 10px 20px;
  border-radius: 50px;
  margin-bottom: 10px;
}

.info-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.info-text {
  font-size: 1.2rem;
}

/* Styles pour la page du tableau de bord de voyage */
.airesponse-dashboard {
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.airesponse-dashboard .budget-summary {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.airesponse-dashboard .budget-summary h3 {
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.airesponse-dashboard .budget-breakdown {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.airesponse-dashboard .budget-item {
  font-size: 1rem;
  color: #333;
}

.airesponse-dashboard .itinerary-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.airesponse-dashboard .days-container {
  display: flex;
  flex-direction: column; /* Aligne les éléments verticalement */
  gap: 20px; /* Espace entre les jours */
}

.airesponse-dashboard .day-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.airesponse-dashboard .day-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #007bff;
}

.airesponse-dashboard .date-range {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
}

.airesponse-dashboard .daily-budget h4 {
  margin-top: 15px;
  font-size: 1.2rem;
}

.airesponse-dashboard .activities-section {
  margin-top: 15px;
}

.airesponse-dashboard .activities-section h4 {
  font-size: 1.1rem;
  color: #007bff;
}

.airesponse-dashboard .activities-section ul {
  list-style: none;
  padding: 0;
}

.airesponse-dashboard .activities-section li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.hotel-section {
  margin-top: 20px;
}

.hotel-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* 3 colonnes */
  gap: 20px;
  margin-top: 10px;
}

.hotel-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.hotel-card p {
  margin: 10px 0;
  font-size: 14px;
}

.hotel-card strong {
  font-size: 16px;
  color: #333;
}

.trip-video {
  margin-top: 20px;
  text-align: center;
}

.trip-video video {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}
/* Conteneur des trois blocs horizontaux */
.info-blocks {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap; /* Pour permettre aux blocs de se replier les uns sous les autres sur petit écran */
}

/* Style pour chaque bloc */
.info-block {
  flex: 1;
  min-width: 300px; /* Assurer une taille minimum pour les blocs */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box; /* Inclure le padding dans la taille du bloc */
}

/* Titre de chaque bloc */
.info-block h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

/* Budget global style */
.budget-breakdown {
  text-align: left;
  line-height: 1.8; /* Espacement entre les lignes */
}

.budget-item {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Style pour la carte */
.map-container {
  height: 100%; /* Pour que la carte occupe toute la hauteur du bloc */
  width: 100%; /* Pour que la carte occupe toute la largeur du bloc */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* Responsive design: Empiler les blocs verticalement sur les petits écrans */
@media (max-width: 768px) {
  .info-blocks {
    flex-direction: column;
    gap: 10px;
  }
}


.progress-bar {
  display: flex;
  height: 20px;
  background-color: #eee;
}

.progress-segment {
  height: 100%;
}

.progress-segment.car {
  background-color: #3498db;
}

.progress-segment.train {
  background-color: #2ecc71;
}

.progress-segment.plane {
  background-color: #e74c3c;
}

.progress-segment.na {
  background-color: #95a5a6;
}

/* Style pour le bloc trajet */
.transport-block {
  background-color: #e0f7fa; /* Couleur de fond légère */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Espacement avec le bloc du jour */
}

/* Style pour le bloc des options de transport */
.transport-options {
  background-color: #fce4ec; /* Couleur différente pour le bloc des options de transport */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Espacement avec les autres sections */
}

/* Classe pour les labels des transports */
.transport-icon {
  font-size: 1.2rem;
  margin-left: 5px;
}

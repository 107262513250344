/* Conteneur global pour l'auth et le switcher de langue */
.auth-language-container {
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  width: 97%;
  padding: 20px;
  position: absolute;
  top: 0;
  z-index: 1;
  color: white;
}

/* Switcher de languee*/
.auth-language-switcher {
  display: flex;
  align-items: center;
  background-color: transparent; 
  border: none;
  position: relative; /* Important pour la position des options */
}

.auth-language-select {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.auth-flag {
  width: 24px; 
  height: auto; 
  margin-left: 10px;
  margin-right: 15px; /* Ajout d'un espace à droite du drapeau pour espacer du dashboard */
  border-radius: 3px; 
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); 
}

/* Options de langue */
.auth-language-options {
  position: absolute;
  top: calc(100% + 5px); /* Positionne la liste juste sous le drapeau, avec un petit espace */
  left: 0; /* Aligner à gauche par rapport au drapeau */
  background-color: rgba(0, 0, 0, 0.8); 
  color: white;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
  z-index: 100; /* Assurez-vous que la liste est au-dessus des autres éléments */
}

.auth-language-option {
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.auth-language-option:hover {
  background-color: #57C1B3; 
  border-radius: 5px;
}

.signup, .login, .logout-button .dashboard {
  margin-left: 15px;
  color: white;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.logo {
  height: 40px;
}

.logout-button, .signup, .login {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin: 0 10px;
  font-size: 16px;
  text-decoration: none;
}

.logout-button:hover, .signup:hover, .login:hover {
  text-decoration: underline;
}
/* question.css */

.question-container {
    margin-bottom: 2rem;
    text-align: left;
  }
  
  .question-container h2 {
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .styled-input,
  .styled-select,
  .styled-textarea {
    width: 100%;
    padding: 0.9rem;
    border-radius: 10px;
    border: 2px solid #ddd;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    background-color: #f9f9f9;
  }
  
  .styled-input:focus,
  .styled-select:focus,
  .styled-textarea:focus {
    border-color: #ff8c42;
    outline: none;
  }
  
  .styled-textarea {
    height: 150px;
    resize: vertical;
  }

/* Home.css */

/* Conteneur principal */
.home-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Vidéo en fond */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Place la vidéo en arrière-plan */
}

/* Contenu principal */
.content {
  z-index: 1; /* Contenu au-dessus de la vidéo */
  text-align: center;
  color: white;
}

/* Titre principal (FlyManta) */
.home-title {
  font-size: 4rem;
  margin-bottom: 40px;
  font-family: 'Pacifico', cursive;
  color: #fff;
  text-shadow: 
  2px 2px 0 #000,  /* Ombre noire en bas à droite */
  -2px 2px 0 #000, /* Ombre noire en bas à gauche */
  2px -2px 0 #000, /* Ombre noire en haut à droite */
  -2px -2px 0 #000; /* Ombre noire en haut à gauche */
}

/* Éléments de connexion et de langue */
.header-elements {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 0;
}

.login {
  font-size: 1rem;
  color: white;
}

.login a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.language-switcher {
  font-size: 1rem;
}

.language-switcher button {
  background: none;
  color: white;
  border: none;
  font-size: 1rem;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.language-switcher button:hover {
  transform: scale(1.1);
}

/* Bouton Démarrer */
.home-button {
  background-color: #2980b9;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.home-button:hover {
  background-color: #1a242f;
  transform: scale(1.05);
}

.home-button:active {
  transform: scale(1);
}

/* Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f4f7fa;
}

.tab-menu {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-menu button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.tab-menu button.active {
  background-color: #0056b3;
}

.tab-content {
  width: 100%;
  max-width: 800px;
}

.previous-trips {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
}

.itinerary-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.itinerary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.itinerary-item:hover {
  background-color: #e9ecef;
}

.itinerary-info {
  flex-grow: 1;
}

.itinerary-date {
  color: #6c757d;
  font-size: 14px;
  margin-top: 5px;
}

.itinerary-actions {
  display: flex;
  gap: 10px;
}

.view-button,
.generate-button,
.delete-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button {
  background-color: #28a745;
  color: white;
}

.view-button:hover {
  background-color: #218838;
}

.generate-button {
  background-color: #17a2b8;
  color: white;
}

.generate-button:hover {
  background-color: #138496;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.user-email {
  margin-top: 20px;
  font-size: 14px;
  color: #333;
}

.dashboard-loader {
  margin-top: 50px;
  font-size: 18px;
  color: #007bff;
}
